export const musics = [
    {
        id: 1,
        title: 'Violão Acústico',
        artist: 'Mayer Bronkx',
        description:
            "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make",
        url: 'https://storage.googleapis.com/pedagogico/frontend-files/aula-react-referencias-eventos/The%20Von%20Trapp%20Family%20Choir%20-%20Alge.mp3',
        cover: 'https://storage.googleapis.com/pedagogico/frontend-files/aula-react-referencias-eventos/img1.jpg',
    },
    {
        id: 2,
        title: 'Just Things',
        artist: 'John Cris',
        description:
            "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make",
        url: 'https://storage.googleapis.com/pedagogico/frontend-files/aula-react-referencias-eventos/Bay%20Street%20Billionaires%20-%20Squadda%20B.mp3',
        cover: 'https://storage.googleapis.com/pedagogico/frontend-files/aula-react-referencias-eventos/img2.jpg',
    },
    {
        id: 3,
        title: 'Maybe Other',
        artist: 'Eva Garden',
        description:
            "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make",
        url: 'https://storage.googleapis.com/pedagogico/frontend-files/aula-react-referencias-eventos/North%20Oakland%20Extasy%20-%20Squadda%20B.mp3',
        cover: 'https://storage.googleapis.com/pedagogico/frontend-files/aula-react-referencias-eventos/img4.jpg',
    },
    {
        id: 4,
        title: "It's simple",
        artist: 'Emily C. M.',
        description:
            "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make",
        url: 'https://storage.googleapis.com/pedagogico/frontend-files/aula-react-referencias-eventos/One%20Time%20-%20Jeremy%20Black.mp3',
        cover: 'https://storage.googleapis.com/pedagogico/frontend-files/aula-react-referencias-eventos/img5.jpg',
    },
];
